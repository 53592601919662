<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="flex items-center p-2 flex-wrap gap-4">
            <select-suggestion
              :max="20"
              :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
              @selected-model="updateGrid"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('Questionnaire')"
              v-model="questionnaire"
              :appendClearOption="true"
              ref="select_questionnaire"
              placeholderText="Digite o nome do questionário"
            />
          </div>
        </fieldset>
      </div>
      <div class="col-span-12" v-show="questionnaire">
        <Grid
          ref="grid"
          :service="service"
          route_name="institutions"
          route_grid_path="/questionnaire_presence/grid"
          :column_formats="{
            user_name: (name) => name ? name : '[Não cadastrado]',
            presence: (present) => present ? 'Presente' : 'Ausente',
            finished_at: (finished_at) => (finished_at) ? $utils.format.date(finished_at, true) : ''
          }"
          :css_formats="{
            user_name: (name) => name ? name : 'text-warning',
            presence: (name) => name ? 'text-success' : 'text-danger'
          }"
          :hide_actions="true"
          :fetchOnMounted="false"
          :extraParams="gridParameters"
          :showExport="true"
          :exportFilename="`Relatório Presença - ${questionnaire && questionnaire.name}`"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
export default {
  components: { SelectSuggestion },

  data: () => ({
    service: null,
    questionnaire: null,
    present: false,
  }),

  watch: {
    questionnaire(val) {
      this.updateGrid()
    },
  },

  computed: {
    validFilters() {
      return this.questionnaire && this.questionnaire.id
    }
  },

  methods: {
    updateGrid() {
      if (this.validFilters) {
        this.grid().model.current_page = 0
        this.grid().fillCustomFilters(
          [{key: 'presence', value: this.present}, {key:'questionnaire_id', value: this.questionnaire.id}]
        )
        this.grid().fetchGridData(false)
      }
    },
    grid() {
      return this.$refs['grid']
    }
  },

  beforeMount() {
    this.service = ReportService.build(this.$vs)
  }
}
</script>
