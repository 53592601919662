var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", [
    _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
      _c("div", { staticClass: "col-span-12" }, [
        _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
          _c("legend", { staticClass: "px-1 font-bol" }, [
            _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "flex items-center p-2 flex-wrap gap-4" },
            [
              _c("select-suggestion", {
                ref: "select_questionnaire",
                class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
                attrs: {
                  max: 20,
                  column: "name,description",
                  model: "ContentQuestionnaire",
                  label: _vm.$t("Questionnaire"),
                  appendClearOption: true,
                  placeholderText: "Digite o nome do questionário",
                },
                on: { "selected-model": _vm.updateGrid },
                model: {
                  value: _vm.questionnaire,
                  callback: function ($$v) {
                    _vm.questionnaire = $$v
                  },
                  expression: "questionnaire",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.questionnaire,
              expression: "questionnaire",
            },
          ],
          staticClass: "col-span-12",
        },
        [
          _c("Grid", {
            ref: "grid",
            attrs: {
              service: _vm.service,
              route_name: "institutions",
              route_grid_path: "/questionnaire_presence/grid",
              column_formats: {
                user_name: function (name) {
                  return name ? name : "[Não cadastrado]"
                },
                presence: function (present) {
                  return present ? "Presente" : "Ausente"
                },
                finished_at: function (finished_at) {
                  return finished_at
                    ? _vm.$utils.format.date(finished_at, true)
                    : ""
                },
              },
              css_formats: {
                user_name: function (name) {
                  return name ? name : "text-warning"
                },
                presence: function (name) {
                  return name ? "text-success" : "text-danger"
                },
              },
              hide_actions: true,
              fetchOnMounted: false,
              extraParams: _vm.gridParameters,
              showExport: true,
              exportFilename:
                "Relatório Presença - " +
                (_vm.questionnaire && _vm.questionnaire.name),
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }